import EventBus from "./services/event-bus";
import Vue from 'vue';
const XF = {};


//Concept Code .. this EventBus should go into seperate package
//@todo require via package.json (like less-modules)
//@todo Code should be nicer..  class/ namespaced..

XF._availableXelosPackages = [];

//is called from package to register with middleware
XF.registerXelosFrontendPackage = function(packageIdentifier){
  console.log(packageIdentifier);
  XF._availableXelosPackages.push(packageIdentifier);
}

XF.selectNavigation = function(navItem){
  //console.log(EventBus._availableXelosPackages);
  if(XF._availableXelosPackages.indexOf('xelos-vue-ui') >= 0){
    alert('theme is available and loaded - execute theme event');
    EventBus.$emit('id-selected', navItem);
  }else{
    alert('legacy theme, redirect location');
  }
}

XF.EventBus = EventBus;

//XF = Object.freeze(XF); todo test if this suits our case
window.XF = XF;

export default XF;
